import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Lib
import { IPageResponse, IStory } from 'lib/Interfaces';

// Services
import { StoryService } from 'services/StoryService';

/*
 *
 * Component
**/
const StoryCollection = ({ query = null }: { query?: string | null; }) => {
    const [page, setPage] = useState<number>(0);
    const [stories, setStories] = useState<IPageResponse<IStory>>();

    useEffect(() => {
        fetchStories();
    }, [query, page]);

    const fetchStories = () => {
        StoryService.getAll({ query, page, size: 20, statuses: ['PUBLISHED', 'UNPUBLISHED', 'DRAFT', 'READY_TO_PUBLISH'] }, (data) => {
            setStories(data);
        });
    }

    const loadMore = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="flex flex-col">
                {
                    stories?.elements.map((story, $key) =>
                        <div key={`story_${$key}`} className="p-5 bg-slate-100 mb-5 last:mb-0">
                            <h3 className="text-lg font-bold text-slate-700">{story.title} ({story.status})</h3>
                            <div>
                                <Link to={`/nav/stories/form?story=${story.uuid}`} className="hover:underline">&#9998; Edit</Link>
                            </div>
                        </div>
                    )
                }
                {
                    (stories?.totalPages && (page + 1) < stories?.totalPages) &&
                    <button onClick={loadMore}>Load more</button>
                }
            </div>
        </>
    );
}

export default StoryCollection;
