// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IGenericError, IMediaDirectory } from 'lib/Interfaces';

type TMediaDirectoryRequest = {
    parent: string | null;
    name: string;
    description?: string | null;
};

/*
 *
 * Service
**/
export const MediaDirectoryService = {
    _name_: 'MediaDirectoryService',
    create: async function(payload: TMediaDirectoryRequest, onSuccessCB: (response: IMediaDirectory) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IMediaDirectory>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.post(`/media/directories`, payload, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'create', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    getAllRoot: async function(onSuccessCB: (response: IMediaDirectory[]) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IMediaDirectory[]>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.get(`/media/directories/root`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'getAllRoot', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    getSubdirectories: async function({ directory }: { directory: string; }, onSuccessCB: (response: IMediaDirectory[]) => void = () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IMediaDirectory[]>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.get(`/media/directories/${directory}/directories`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'getSubdirectories', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    }
}