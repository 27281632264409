import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// Interfaces
import { IMediaDirectory, IMediaResource } from 'lib/Interfaces';

// Services
import { MediaDirectoryService } from 'services/MediaDirectoryService';
import { MediaResourceService } from 'services/MeriaResourceService';

// static files
import youtubeIcon from 'assets/images/youtube.png';

/*
 *
 * Component
**/
const MediaDrive = () => {
    const _initialized_ = useRef<boolean>(false);
    const [directories, setDirectories] = useState<IMediaDirectory[]>();
    const [resources, setResources] = useState<IMediaResource[]>();
    const [directory, setDirectory] = useState<IMediaDirectory>();
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        if (_initialized_.current) return;
        // -> Start: execute once
        fetchRootDirectories();
        // End: execute once <-
        _initialized_.current = true;
    }, []);

    useEffect(() => {
        setResources([]);

        if (directory) {
            fetchSubdirectories();
            fetchResources();
        } else if (_initialized_.current) {
            fetchRootDirectories();
        }
    }, [directory]);

    const fetchRootDirectories = () => {
        MediaDirectoryService.getAllRoot((data) => {
            setDirectories(data);
        });
    }

    const fetchSubdirectories = () => {
        MediaDirectoryService.getSubdirectories({ directory: directory?.uuid as string }, (data) => {
            setDirectories(data);
        });
    }

    const fetchResources = () => {
        MediaResourceService.getAll({ directory: directory?.uuid as string, page: 0 }, (data) => {
            setResources(data.elements);
        });
    }

    const selectDirectory = (dir: IMediaDirectory) => {
        setDirectory(dir);
    }

    const goBack = () => {
        setDirectory(directory?.parent);
    }

    const selectResource = (resource: IMediaResource) => {
        setMessage(`${resource.uuid}`);
    }

    const getResourcePreview = (resource: IMediaResource) => {
        if (resource.type === 'IMAGE') {
            if (resource.origin === 'LOCAL') {
                const src = `${process.env.REACT_APP_CONTENT_URL}/media/${resource.uri}`;
                return (<Link to={src} target="_blank"><img src={src} className="w-full h-full object-cover object-center"/></Link>);
            } else {
                return (<Link to={resource.uri} target="_blank"><img src={resource.uri} className="w-full h-full object-cover object-center"/></Link>);
            }
        } else if (resource.type === 'VIDEO') {
            if (resource.origin === 'YOUTUBE') {
                return (<Link to={resource.uri} target="_blank"><img src={youtubeIcon} className="w-full h-full object-cover object-center"/></Link>);
            }
        }
    }

    return (
        <>
            <div className="text-lg font-bold mb-2">
                <button type="button" onClick={goBack}>..</button>
            </div>
            <div className="flex flex-col lg:flex-row flex-wrap gap-2">
                {
                    directories?.map((dir, $key_d) =>
                        <div key={`dir_${$key_d}`} className="w-[150px] h-[150px] border flex flex-col flex-wrap justify-center items-center p-2">
                            <button type="button" onClick={() => selectDirectory(dir)} className="text-sm hover:underline">
                                {dir.name} ({dir.childrenCount + dir.resourcesCount})
                            </button>
                        </div>
                    )
                }
                {
                    resources?.map((res, $key_r) =>
                        <div key={`res_${$key_r}`} className="w-[150px] h-[150px] border flex flex-col p-2">
                            <div className="relative h-[100px] w-full">
                                {getResourcePreview(res)}
                            </div>
                            <div className="h-50px overflow-hidden text-wrap text-center">
                                <button type="button" className="text-xs hover:underline" onClick={() => selectResource(res)}>
                                    {res.name}
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>
            <p>{message}</p>
        </>
    );
}

export default MediaDrive;
