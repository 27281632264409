import { useState, useEffect, useRef } from 'react';

// Lib
import { IMediaDirectory } from 'lib/Interfaces';

// Services
import { MediaDirectoryService } from 'services/MediaDirectoryService';

type TMediaDirectory = {
    parent: string | null;
    name: string;
    description?: string | null;
};

/*
 *
 * Component
**/
const MediaDirectoryForm = ({ onCreateCB = () => {} }: { onCreateCB?: (name: string) => void; }) => {
    const _initialized_ = useRef<boolean>(false);
    const [mediaDirectories, setMediaDirectories] = useState<IMediaDirectory[]>();
    const [mediaDirectoryForm, setMediaDirectoryForm] = useState<TMediaDirectory>({ parent: null, name: '' });
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        if (_initialized_.current) return;
        // -> Start: execute once
        fetchMediaDirectories();
        // End: execute once <-
        _initialized_.current = true;
    }, []);

    const fetchMediaDirectories = () => {
        MediaDirectoryService.getAllRoot((data) => {
            setMediaDirectories(data);
        });
    }

    const create = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const response = await MediaDirectoryService.create(mediaDirectoryForm);
        setMessage(response.data?.uuid as string);
        onCreateCB(response.data?.name as string);
    }

    const handleDirectoryChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setMediaDirectoryForm({
            ...mediaDirectoryForm,
            parent: event.currentTarget.value
        });
    }

    const handleNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setMediaDirectoryForm({
            ...mediaDirectoryForm,
            name: event.currentTarget.value
        });
    }

    const handleDescriptionChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setMediaDirectoryForm({
            ...mediaDirectoryForm,
            description: event.currentTarget.value
        });
    }

    return (
        <>
            <form id="mediar-directory" className="w-full grid grid-flow-row gap-3" onSubmit={create}>
                <select onChange={handleDirectoryChange} className="block w-full border rounded-sm p-3">
                    <option>-- Root directory --</option>
                    {
                        mediaDirectories?.map((dir, $key) =>
                            <option key={`dir_${$key}`} value={dir.uuid}>{dir.name}</option>
                        )
                    }
                </select>
                <input type="text" name="name" onChange={handleNameChange} className="block w-full border rounded-sm p-3" placeholder="* Name"/>
                <input type="text" name="description" onChange={handleDescriptionChange} className="block w-full border rounded-sm p-3" placeholder="Description"/>
                <button type="submit" className="block bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3">Create</button>
            </form>
            <p>{message}</p>
        </>
    );
}

export default MediaDirectoryForm;
