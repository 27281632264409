/*
 *
 * Component
**/
const Footer = () => {
  return (
    <>
      <div className="flex flex-col py-5">
        <p className="text-slate-500">Verbose Mag © 2024</p>
      </div>
    </>
  );
}

export default Footer;
