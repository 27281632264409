// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IGenericError, IGroupPlacement } from "lib/Interfaces";

type TPlacementRequest = {
    type: string;
    associatedUuid: string;
};

type TGroupPlacementRequest = {
    group: string;
    order: number;
    placement: TPlacementRequest;
};

/*
 *
 * Service
**/
export const GroupPlacementService = {
    _name_: 'GroupPlacementService',
    create: async function(payload: TGroupPlacementRequest, onSuccessCB: (response: IGroupPlacement) => void = () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IGroupPlacement>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.post(`/placements`, payload, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'create', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
}