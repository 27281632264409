import { useState, useEffect, useRef } from 'react';

// Lib
import { IMediaDirectory } from 'lib/Interfaces';

// Services
import { MediaResourceService } from 'services/MeriaResourceService';
import { MediaDirectoryService } from 'services/MediaDirectoryService';

type TMediaResource = {
    directory: string;
    name: string;
    description?: string | null;
    uri: string;
    origin: string;
    type: string;
    metadata: object;
};

/*
 *
 * Component
**/
const MediaResourceForm = ({ fileUri = '', onCreateCB = () => {} }: { fileUri?: string; onCreateCB?: (name: string) => void; }) => {
    const _initialized_ = useRef<boolean>(false);
    const [mediaDirectories, setMediaDirectories] = useState<IMediaDirectory[]>();
    const [mediaResourceForm, setMediaResourceForm] = useState<TMediaResource>({ directory: '', name: '', uri: '', origin: '', type: '', metadata: {} });
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        if (_initialized_.current) return;
        // -> Start: execute once
        fetchMediaDirectories();
        // End: execute once <-
        _initialized_.current = true;
    }, []);

    useEffect(() => {
        setMediaResourceForm({
            ...mediaResourceForm,
            uri: fileUri
        });
    }, [fileUri]);

    const fetchMediaDirectories = () => {
        MediaDirectoryService.getAllRoot((data) => {
            setMediaDirectories(data);
        });
    }

    const create = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const response = await MediaResourceService.create(mediaResourceForm);
        setMessage(response.data?.uuid as string);
        onCreateCB(response.data?.name as string);
    }

    const handleDirectoryChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setMediaResourceForm({
            ...mediaResourceForm,
            directory: event.currentTarget.value
        });
    }

    const handleNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setMediaResourceForm({
            ...mediaResourceForm,
            name: event.currentTarget.value
        });
    }

    const handleDescriptionChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setMediaResourceForm({
            ...mediaResourceForm,
            description: event.currentTarget.value
        });
    }

    const handleUriChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setMediaResourceForm({
            ...mediaResourceForm,
            uri: event.currentTarget.value
        });
    }

    const handleOriginChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setMediaResourceForm({
            ...mediaResourceForm,
            origin: event.currentTarget.value
        });
    }

    const handleTypeChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setMediaResourceForm({
            ...mediaResourceForm,
            type: event.currentTarget.value
        });
    }

    return (
        <>
            <form id="mediar-resource" className="w-full grid grid-flow-row gap-3" onSubmit={create}>
                <select onChange={handleDirectoryChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select directory --</option>
                    {
                        mediaDirectories?.map((dir, $key) =>
                            <option key={`dir_${$key}`} value={dir.uuid}>{dir.name}</option>
                        )
                    }
                </select>
                <input type="text" name="name" onChange={handleNameChange} className="block w-full border rounded-sm p-3" placeholder="* Name"/>
                <input type="text" name="description" onChange={handleDescriptionChange} className="block w-full border rounded-sm p-3" placeholder="Description"/>
                <input type="text" name="uri" onChange={handleUriChange} value={mediaResourceForm.uri} className="block w-full border rounded-sm p-3" placeholder="* Uri"/>
                <select onChange={handleOriginChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select origin --</option>
                    <option value="LOCAL">Local</option>
                    <option value="GOOGLE_DRIVE">Google Drive</option>
                    <option value="ONE_DRIVE">One Drive</option>
                    <option value="DROPBOX">Dropbox</option>
                    <option value="AWS_S3">AWS S3</option>
                    <option value="YOUTUBE">YouTube</option>
                    <option value="OTHER">Other</option>
                </select>
                <select onChange={handleTypeChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select type --</option>
                    <option value="TEXT">Text</option>
                    <option value="PDF">PDF</option>
                    <option value="WORD">Word</option>
                    <option value="EXCEL">Excel</option>
                    <option value="PPT">PPT</option>
                    <option value="IMAGE">Image</option>
                    <option value="AUDIO">Audio</option>
                    <option value="VIDEO">Video</option>
                    <option value="LINK">Link</option>
                    <option value="OTHER">Other</option>
                </select>
                <button type="submit" className="block bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3">Create</button>
            </form>
            <p>{message}</p>
        </>
    );
}

export default MediaResourceForm;
