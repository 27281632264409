import { useEffect, useState } from 'react';

// Components
import FileUploadForm from 'components/media_lib/FileUploadForm';
import MediaResourceForm from 'components/media_lib/MediaResourceForm';
import MediaDirectoryForm from 'components/media_lib/MediaDirectoryForm';
import MediaDrive from 'components/media_lib/MediaDrive';

/*
 *
 * View
**/
const MediaLibView = () => {
  const [fileUploadUri, setFileUploadUri] = useState<string>('');

  useEffect(() => {
    document.title = "Media Library - Verbose Mag";
  }, []);

  const onFileUpload = (fileUri: string) => {
    setFileUploadUri(fileUri);
  }

  const onMediaResourceCreate = (name: string) => {
    console.log(name);
  }

  const onMediaDirectoryCreate = (name: string) => {
    console.log(name);
  }

  return (
    <>
      <h1 className="text-2xl font-bold text-slate-700 py-5">Media Library</h1>
      <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
      <div className="p-5 border">
        <FileUploadForm autoUpload={true} onUploadCB={onFileUpload}/>
      </div>
      <div className="mt-5 p-5 border">
        <MediaResourceForm fileUri={fileUploadUri} onCreateCB={onMediaResourceCreate}/>
      </div>
      <div className="mt-5 p-5 border">
        <MediaDirectoryForm onCreateCB={onMediaDirectoryCreate}/>
      </div>
      <div className="mt-5 p-5 border">
        <MediaDrive/>
      </div>
    </>
  );
}

export default MediaLibView;
