import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Interfaces
import { IUser } from 'lib/Interfaces';

// Services
import { AuthService } from 'services/AuthService';

/*
 *
 * Lib
**/
export const SecuredRoute = ({ children }: Readonly<{ children: React.ReactNode }>) => {
  const location = useLocation();
  const [user, setUser] = useState<IUser>({ name: '' });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const isAuthenticated = await AuthService.isAuthenticated();

    if (isAuthenticated) {
      setUser({ name: "System Admin" });
    }
    
    setIsAuthenticated(isAuthenticated);
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <>
        <div className="flex flex-col justify-center items-center my-5">
          <h4 className="text-lg font-bold text-slate-500">
            Loading...
          </h4>
        </div>
      </>
    );
  }

  if (!isAuthenticated) {
    return (<Navigate to={`/signin`} state={{ from: location }} replace/>);
  }

  return (
    <>
      {children}
    </>
  );
}
