import { useEffect } from 'react';

// Components
import GroupPlacementForm from 'components/placement/GroupPlacementForm';

/*
 *
 * View
**/
const PlacementView = () => {

    useEffect(() => {
        document.title = "Placements - Verbose Mag";
    }, []);

    const onGroupPlacementCreate = (name: string) => {
        console.log(name);
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-slate-700 py-5">Placements</h1>
            <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
            <div className="p-5 border">
                <GroupPlacementForm onCreateCB={onGroupPlacementCreate} />
            </div>
        </>
    );
}

export default PlacementView;
