import React from 'react';
import { Outlet } from "react-router-dom";

/*
 *
 * Layout
**/
const ErrorLayout = () => {
  return (
    <React.Fragment>
      <main>
        <div className="container h-full flex flex-col justify-center items-center">
          <Outlet/>
        </div>
      </main>
    </React.Fragment>
  );
}

export default ErrorLayout;
