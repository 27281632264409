import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

// Components
import StoryForm from 'components/story/StoryForm';

/*
 *
 * View
**/
const StoryView = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Story - Verbose Mag";
    }, []);

    const onStoryCreate = (title: string) => {
        console.log(title);
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-slate-700 py-5">Story</h1>
            <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
            <div className="mb-5 flex flex-row justify-end">
                <Link to="/nav/stories" className="bg-primary hover:bg-secondary text-white text-base p-2 rounded">All stories</Link>
            </div>
            <div className="p-5 border">
                <StoryForm id={searchParams.get("story")} onCreateCB={onStoryCreate} />
            </div>
        </>
    );
}

export default StoryView;
