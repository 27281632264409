import axios from 'axios';

export const rest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  },
});

export const getSecurityToken = () => {
  return atob(localStorage.getItem("__ak") as string);
}

export const config = ({ elem = rest, reset = (err: object) => {} }) => {
  elem.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response?.status === 401) {
      reset(error);
    }
    return Promise.reject(error);
  });
};
