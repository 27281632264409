import { useState, useEffect } from 'react';

// Services
import { FileUploadService } from 'services/FileUploadService';

/*
 *
 * Component
**/
const FileUploadForm = ({ autoUpload = false, onUploadCB = () => {} }: { autoUpload?: boolean; onUploadCB?: (fileUri: string) => void; }) => {
    const [fileUploadForm, setFileUploadForm] = useState<{ file: string | null }>({ file: null });
    const [fileUploadMessage, setFileUploadMessage] = useState<string | null>(null);

    useEffect(() => {
        if (fileUploadForm.file && autoUpload) {
            upload();
        }
    }, [fileUploadForm]);

    const upload = async (event: React.FormEvent<HTMLButtonElement> | null = null) => {
        event?.preventDefault();

        const payload = { 
            files: [
                {
                    source: fileUploadForm.file as string
                }
            ] 
        };

        const response = await FileUploadService.upload(payload);
        
        if (response.status === 0) {
            const fileUri = response.data?.files[0] as string;
            setFileUploadMessage(fileUri);
            onUploadCB(fileUri)
        } else {
            setFileUploadMessage(response.message);
        }
    }

    const handleFileChange = (event: React.FormEvent<HTMLInputElement>): void => {
        if (event.currentTarget.files) {
            const file = event.currentTarget.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = function() {
                setFileUploadForm({
                    ...fileUploadForm,
                    file: reader.result as string
                });
            };
        }
    }

    return (
        <>
            <form id="file-upload" className="w-full grid grid-flow-row gap-3">
                <input type="file" name="file" onChange={handleFileChange} className="block w-full border rounded-sm p-3"/>
                <button type="button" onClick={upload} className={`${autoUpload ? 'hidden' : 'block'} bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3`}>Upload</button>
            </form>
            <p className="text-grey-700">{fileUploadMessage}</p>
        </>
    );
}

export default FileUploadForm;
