import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Lib
import { SecuredRoute } from 'lib/SecuredRoute';

// Layouts
import PublicPageLayout from 'views/layout/PublicPageLayout';
import ErrorLayout from 'views/layout/ErrorLayout';
import AppLayout from 'views/layout/AppLayout';

// Views
import Error404View from 'views/Error404View';
import SigninView from 'views/SigninView';
import DashboardView from 'views/DashboardView';
import MediaLibView from 'views/MediaLibView';
import PlacementView from 'views/PlacementView';
import StoryCollectionView from 'views/StoryCollectionView';
import StoryView from 'views/StoryView';
import TagCollectionView from 'views/TagCollectionView';
import TagView from 'views/TagView';
import NewsletterSubscriptionCollectionView from 'views/NewsletterSubscriptionCollectionView';

/*
 *
 * Component
**/
const App = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Routes>
          {/* Redirects */}
          <Route path="/" element={<Navigate to="/nav/dashboard" replace/>}/>
          <Route path="/home" element={<Navigate to="/nav/dashboard" replace/>}/>
          <Route path="/index" element={<Navigate to="/nav/dashboard" replace/>}/>
          <Route path="/dashboard" element={<Navigate to="/nav/dashboard" replace/>}/>

          {/* Public pages */}
          <Route path='/' element={<PublicPageLayout/>}>
            <Route path="signin" element={<SigninView/>}/>
          </Route>
          
          {/* Secured pages */}
          <Route path='/nav/' element={<AppLayout/>}>
            <Route index path="dashboard" element={<SecuredRoute><DashboardView/></SecuredRoute>}/>
            <Route index path="media-lib" element={<SecuredRoute><MediaLibView/></SecuredRoute>}/>
            <Route index path="stories" element={<SecuredRoute><StoryCollectionView/></SecuredRoute>}/>
            <Route index path="stories/form" element={<SecuredRoute><StoryView/></SecuredRoute>}/>
            <Route index path="tags" element={<SecuredRoute><TagCollectionView/></SecuredRoute>}/>
            <Route index path="tags/form" element={<SecuredRoute><TagView/></SecuredRoute>}/>
            <Route index path="placements" element={<SecuredRoute><PlacementView/></SecuredRoute>}/>
            <Route index path="newsletter/subscriptions" element={<SecuredRoute><NewsletterSubscriptionCollectionView/></SecuredRoute>}/>
          </Route>
          
          {/* Errors */}
          <Route path='/*' element={<ErrorLayout/>}>
            <Route path="*" element={<Error404View/>}/>
          </Route>
        </Routes>
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
