import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

// Components
import TagForm from 'components/tag/TagForm';

/*
 *
 * View
**/
const TagView = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Tag - Verbose Mag";
    }, []);

    const onTagCreate = (name: string) => {
        console.log(name);
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-slate-700 py-5">Tag</h1>
            <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
            <div className="mb-5 flex flex-row justify-end">
                <Link to="/nav/tags" className="bg-primary hover:bg-secondary text-white text-base p-2 rounded">All tags</Link>
            </div>
            <div className="p-5 border">
                <TagForm id={searchParams.get("tag")} onCreateCB={onTagCreate} />
            </div>
        </>
    );
}

export default TagView;
