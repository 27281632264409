import { useState, useEffect } from 'react';

// Lib
import { ITag } from 'lib/Interfaces';

// Services
import { TagService } from 'services/TagService';

type TTag = {
    name: string;
    slug: string;
    description?: string | null;
};

/*
 *
 * Component
**/
const TagForm = ({ id, onCreateCB = () => {} }: { id?: string | null; onCreateCB?: (name: string) => void; }) => {
    const [message, setMessage] = useState<string>('');
    const [tagForm, setTagForm] = useState<TTag>({ name: '', slug: '', description: '' });

    useEffect(() => {
        if (id) {
            fetchTag(id);
        }
    }, [id]);

    const fetchTag = (id: string): void => {
        TagService.get({ id }, (data) => {
            setTagForm(mapTag(data));
        });
    }

    const mapTag = (tag: ITag): TTag => {
        const mTag = {
            name: tag.name, 
            slug: tag.slug,
            description: tag.description,
        };

        return mTag;
    }

    const createOrEdit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        var response;
        if (id) {
            response = await TagService.edit({ ...tagForm, id: id });
        } else {
            response = await TagService.create(tagForm);
        }
        
        setMessage(response.data?.name as string);
        onCreateCB(response.data?.name as string);
    }

    const handleSlugChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setTagForm({
            ...tagForm,
            slug: event.currentTarget.value
        });
    }

    const handleNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setTagForm({
            ...tagForm,
            name: event.currentTarget.value
        });
    }

    const handleDescriptionChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setTagForm({
            ...tagForm,
            description: event.currentTarget.value
        });
    }

    return (
        <>
            <form id="tag-form" className="w-full grid grid-flow-row gap-3" onSubmit={createOrEdit}>
                <input type="text" name="slug" value={tagForm.slug} onChange={handleSlugChange} className="block w-full border rounded-sm p-3" placeholder="* Slug"/>
                <input type="text" name="name" value={tagForm.name} onChange={handleNameChange} className="block w-full border rounded-sm p-3" placeholder="* Name"/>
                <input type="text" name="description" value={tagForm.description || ''} onChange={handleDescriptionChange} className="block w-full border rounded-sm p-3" placeholder="Description"/>
                <button type="submit" className="block bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3">Save</button>
            </form>
            <p>{message}</p>
        </>
    );
}

export default TagForm;
