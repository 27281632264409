import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// Components
import SubscriptionCollection from 'components/newsletter/SubscriptionCollection';

/*
 *
 * View
**/
const NewsletterSubscriptionCollectionView = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Subscriptions - Verbose Mag";
    }, []);

    return (
        <>
            <h1 className="text-2xl font-bold text-slate-700 py-5">Subscriptions</h1>
            <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
            <div className="p-5 border">
                <SubscriptionCollection query={searchParams.get("q")}/>
            </div>
        </>
    );
}

export default NewsletterSubscriptionCollectionView;
