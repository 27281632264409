// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IGenericError, IPageResponse, IMediaResource } from 'lib/Interfaces';

type TMediaResourceRequest = {
    directory: string;
    name: string;
    description?: string | null;
    uri: string;
    origin: string;
    type: string;
    metadata: object;
};

/*
 *
 * Service
**/
export const MediaResourceService = {
    _name_: 'MediaResourceService',
    create: async function(payload: TMediaResourceRequest, onSuccessCB: (response: IMediaResource) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IMediaResource>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.post(`/media/resources`, payload, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'create', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    getAll: async function({ directory, page = 0 }: { directory: string; page?: number; }, onSuccessCB: (response: IPageResponse<IMediaResource>) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IPageResponse<IMediaResource>>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: { directory, page }
        };
        
        return rest.get(`/media/resources`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'getAll', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    get: async function({ id }: { id: string; }, onSuccessCB: (response: IMediaResource) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IMediaResource>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.get(`/media/resources/${id}`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'get', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    }
}