// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IGenericError, IPageResponse, INewsletterSubscription } from 'lib/Interfaces';

/*
 *
 * Service
**/
export const NewsletterSubscriptionService = {
    _name_: 'NewsletterSubscriptionService',
    getAll: async function({ query = null, page = 0, size = 20 }: { query: string | null; page?: number; size?: number; }, onSuccessCB: (response: IPageResponse<INewsletterSubscription>) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IPageResponse<INewsletterSubscription>>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: { query, page, size }
        };
        
        return rest.get(`/newsletter/subscriptions`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'getAll', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
}