import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Services
import { AuthService } from 'services/AuthService';

/*
 *
 * View
**/
const DashboardView = () => {
  const navigate = useNavigate();
  const [signinForm, setSigninForm] = useState<{ password: string }>({ password: '_--*--_' });
  const [signinMessage, setSigninMessage] = useState<string>('');

  useEffect(() => {
    document.title = "Signin - Verbose Mag";
  }, []);

  const signin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await AuthService.signin(signinForm);
    if (response.status === 0) {
      navigate('/nav/dashboard');
    } else {
      setSigninMessage("Signin error.");
    }
  };

  const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSigninForm({
      ...signinForm,
      password: event.currentTarget.value
    });
  }

  return (
    <div className="w-full lg:w-2/5">
      <h2 className="text-center font-bold text-slate-700 text-xl">Sign in to your account</h2>
      <form id="signin-form" className="my-5 w-full" onSubmit={signin}>
        <input type="password" name="password" onChange={handlePasswordChange} className="block w-full border rounded-sm p-3" placeholder="* Password" />
        <button type="submit" className="block w-full bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3">Sign in</button>
      </form>
      <p className="text-rose-500 text-center">{signinMessage}</p>
    </div>
  );
}

export default DashboardView;
