import { Link } from 'react-router-dom';

// Static files
import navJson from 'assets/data/nav.json';

/*
 *
 * View
**/
const DashboardView = () => {
  return (
    <>
      <h1 className="text-2xl font-bold text-slate-700 py-5">My Dashboard</h1>
      <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
      <div className="flex flex-col lg:flex-row flex-wrap gap-5">
        {
          navJson.map((nav, $key) =>
            <div key={`menu_item_${$key}`} className="w-[250px] h-[250px] border rounded-md shadow-lg">
              <div className="h-full bg-teal-500 flex flex-row justify-center items-center">
                <Link to={nav.url} className="text-white text-lg font-bold">{nav.title}</Link>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}

export default DashboardView;
