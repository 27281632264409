
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Services
import { AuthService } from 'services/AuthService'

// Static files
import navJson from 'assets/data/nav.json';

/*
 *
 * Component
**/
const Navigation = () => {
  const navigate = useNavigate();
  
  const signout = () => {
    AuthService.signout();
    navigate('/');
  }
  
  return (
    <>
      <div className="my-2">
        <ul className="list-none text-base">
          <li className="inline-block mr-5 font-bold">
            Verbose Mag<sup className="text-xs text-secondary font-thin px-1">Admin [{process.env.REACT_APP_VERSION}]</sup>
          </li>
          <li className="inline-block mr-5">
            <Link to='/nav/dashboard' className="text-slate-700 hover:text-primary transition-all duration-200">&#9784; Dashboard</Link>
          </li>
          {
            navJson.map((nav, $key) =>
              <li key={`nav_${$key}`} className="inline-block mr-5">
                <Link to={nav.url} className="text-slate-700 hover:text-primary transition-all duration-200">{nav.title}</Link>
              </li>
            )
          }
          <li className="inline-block">
            <button onClick={signout} className="text-slate-700 hover:text-primary transition-all duration-200">&#9823; Sign out</button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
