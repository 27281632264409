import { useState, useEffect } from 'react';

// Lib
import { IPageResponse, INewsletterSubscription } from 'lib/Interfaces';

// Services
import { NewsletterSubscriptionService } from 'services/NewsletterSubscriptionService';

/*
 *
 * Component
**/
const SubscriptionCollection = ({ query = null }: { query?: string | null; }) => {
    const [page, setPage] = useState<number>(0);
    const [subscription, setSubscriptions] = useState<IPageResponse<INewsletterSubscription>>();

    useEffect(() => {
        fetchSubscriptions();
    }, [query, page]);

    const fetchSubscriptions = () => {
        NewsletterSubscriptionService.getAll({ query, page, size: 20 }, (data) => {
            setSubscriptions(data);
        });
    }

    const loadMore = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="flex flex-col">
                {
                    subscription?.elements?.map((subs, $key) =>
                        <div key={`subs_${$key}`} className="p-5 bg-slate-100 mb-5 last:mb-0">
                            <h3 className="text-lg font-bold text-slate-700">{subs.email} ({`subscribed: ${subs.subscribed}`})</h3>
                            <h4 className="text-sm text-slate-700">{subs.firstName} {subs.lastName}</h4>
                        </div>
                    )
                }
                {
                    (subscription?.totalPages && (page + 1) < subscription?.totalPages) &&
                    <button onClick={loadMore}>Load more</button>
                }
            </div>
        </>
    );
}

export default SubscriptionCollection;