// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IGenericError, IPageResponse, IStory } from 'lib/Interfaces';

type TStoryRequest = {
    id?: string | null;
    slug: string;
    title: string;
    subtitle?: string | null;
    body?: string | null;
    status: string;
    headers: string[];
    topics: string[];
    tags?: string[] | null;
    metadata: TMetadataRequest;
};

type TMetadataRequest = {
    readTime: number;
    locale: string;
    category: string;
    authors: TAuthorRequest[];
    sources: TSourceRequest[];
};

type TAuthorRequest = {
    id: string;
    firstName: string;
    lastName: string;
};

type TSourceRequest = {
    name: string;
    notes: string | null;
    link: string;
};

/*
 *
 * Service
**/
export const StoryService = {
    _name_: 'StoryService',
    create: async function(payload: TStoryRequest, onSuccessCB: (response: IStory) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IStory>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.post(`/stories`, payload, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'create', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    edit: async function(payload: TStoryRequest, onSuccessCB: (response: IStory) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IStory>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.put(`/stories/${payload.id}`, payload, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'edit', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    getAll: async function({ query = null, page = 0, size = 20, statuses = [] }: { query?: string | null, page?: number; size?: number; statuses?: string[]; }, onSuccessCB: (response: IPageResponse<IStory>) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IPageResponse<IStory>>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {
                query,
                page,
                size,
                statuses: statuses?.join(",")
            }
        };
        
        return rest.get(`/stories`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'getAll', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    get: async function({ id }: { id: string; }, onSuccessCB: (response: IStory) => void= () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IStory>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.get(`/stories/${id}`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'get', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
}