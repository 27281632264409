// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IGenericError, IGroup } from "lib/Interfaces";

/*
 *
 * Service
**/
export const GroupService = {
    _name_: 'GroupService',
    getAll: async function(onSuccessCB: (response: IGroup[]) => void = () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IGroup[]>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.get(`/placements/groups`, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'getAll', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    }
}
