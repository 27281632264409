import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

// Components
import TagCollection from 'components/tag/TagCollection';

/*
 *
 * View
**/
const TagCollectionView = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Tags - Verbose Mag";
    }, []);

    return (
        <>
            <h1 className="text-2xl font-bold text-slate-700 py-5">Tags</h1>
            <hr className="h-px w-full m-auto mb-10 bg-slate-200 border-0"/>
            <div className="mb-5 flex flex-row justify-end">
                <Link to="/nav/tags/form" className="bg-primary hover:bg-secondary text-white text-base p-2 rounded">Create tag</Link>
            </div>
            <div className="p-5 border">
                <TagCollection query={searchParams.get("q")} />
            </div>
        </>
    );
}

export default TagCollectionView;
