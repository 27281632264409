// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest } from 'lib/http';
import { IApiResponse, IGenericError } from "lib/Interfaces";

/*
 *
 * Service
**/
export const AuthService = {
    _name_: 'AuthService',
    signin: async function({ password }: { password: string; }, onSuccessCB: (response: object) => void = () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<object>> {
        const config = {
            headers: {
            },
            params: {}
        };

        const payload = {
            api_key: password
        };

        return rest.post(`/authenticate`, payload, config).then(response => {
            localStorage.setItem("__ak", btoa(response.data.apiKey));
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'signin', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    },
    signout: (): void => {
        localStorage.clear();
    },
    isAuthenticated: async function(): Promise<boolean> {
        try {
            const apiKey = atob(localStorage.getItem("__ak") as string);
            const response = await this.signin({ password: apiKey });
            return response.status === 0;
        } catch (err) {
            return false
        }
    },
}