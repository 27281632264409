import { useState, useEffect } from 'react';

// Lib
import { IMediaResource, IStory, IStoryMetadata, ITag, ITopic } from 'lib/Interfaces';

// Components
import StoryEditor from 'components/editor/StoryEditor';

// Services
import { StoryService } from 'services/StoryService';

type TStory = {
    title: string;
    slug: string;
    subtitle?: string | null;
    body?: string | null;
    headers: string[];
    topics: string[];
    tags?: string[] | null;
    status: string;
    metadata: TMetadata;
};

type TMetadata = {
    readTime: number;
    locale: string;
    category: string;
    authors: TAuthor[];
    sources: TSource[];
};

type TAuthor = {
    id: string;
    firstName: string;
    lastName: string;
};

type TSource = {
    name: string;
    notes: string | null;
    link: string;
};

/*
 *
 * Component
**/
const StoryForm = ({ id, onCreateCB = () => {} }: { id?: string | null; onCreateCB?: (title: string) => void; }) => {
    const [message, setMessage] = useState<string>('');
    const [storyForm, setStoryForm] = useState<TStory>({ title: '', slug: '', headers: [], topics: [], status: '', metadata: {
        readTime: 0, locale: '', category: '', authors: [{ id: "1", firstName: "Πέτρος", lastName: "Κοβάτσης" }], sources: []
    } });
    const [headers, setHeaders] = useState<IMediaResource[]>([]);

    useEffect(() => {
        if (id) {
            fetchStory(id);
        }
    }, [id]);

    const fetchStory = (id: string): void => {
        StoryService.get({ id }, (data) => {
            setStoryForm(mapStory(data));
            setHeaders(data.headers);
        });
    }

    const mapStory = (story: IStory): TStory => {
        const mStory = {
            title: story.title, 
            slug: story.slug,
            subtitle: story.subtitle,
            body: story.body, 
            headers: mapHeaders(story.headers), 
            topics: mapTopics(story.topics),
            tags: mapTags(story.tags),
            status: story.status, 
            metadata: {
                readTime: (story.metadata as IStoryMetadata).readTime, 
                locale: (story.metadata as IStoryMetadata).locale, 
                category: (story.metadata as IStoryMetadata).category, 
                authors: [{ id: "1", firstName: "Πέτρος", lastName: "Κοβάτσης" }], 
                sources: []
            } 
        };

        return mStory;
    }

    const mapHeaders = (mediaResources: IMediaResource[]): string[] => {
        return mediaResources.map(r => r.uuid);
    }

    const mapTopics = (topics: ITopic[]): string[] => {
        return topics.map(t => t.slug);
    }

    const mapTags = (tags: ITag[]): string[] => {
        return tags.map(t => t.slug);
    }

    const createOrEdit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        var response;
        if (id) {
            response = await StoryService.edit({ ...storyForm, id: id });
        } else {
            response = await StoryService.create(storyForm);
        }
        
        setMessage(response.data?.title as string);
        onCreateCB(response.data?.title as string);
    }

    const handleSlugChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            slug: event.currentTarget.value
        });
    }

    const handleHeadersChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            headers: [event.currentTarget.value]
        });
    }

    const handleTitleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            title: event.currentTarget.value
        });
    }

    const handleSubtitleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            subtitle: event.currentTarget.value
        });
    }

    const handleBodyChange = (content: string): void => {
        setStoryForm({
            ...storyForm,
            body: content
        });
    }

    const handleTopicsChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            topics: event.currentTarget.value.split(",")
        });
    }

    const handleTagsChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            tags: event.currentTarget.value.split(",")
        });
    }

    const handleStatusChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setStoryForm({
            ...storyForm,
            status: event.currentTarget.value
        });
    }

    const handleMetadataCategoryChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setStoryForm({
            ...storyForm,
            metadata: {
                ...storyForm.metadata,
                category: event.currentTarget.value
            }
        });
    }

    const handleMetadataLocaleChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setStoryForm({
            ...storyForm,
            metadata: {
                ...storyForm.metadata,
                locale: event.currentTarget.value
            }
        });
    }

    const handleMetadataReadTimeChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setStoryForm({
            ...storyForm,
            metadata: {
                ...storyForm.metadata,
                readTime: parseInt(event.currentTarget.value)
            }
        });
    }

    const previewHeader = (resource: IMediaResource) => {
        if (resource?.type === 'IMAGE') {
            if (resource.origin === 'LOCAL') {
                const src = `${process.env.REACT_APP_CONTENT_URL}/media/${resource.uri}`;
                return (<img key={`img_${resource.uuid}`} src={src} className="w-full h-full object-cover object-center"/>);
            } else {
                return (<img key={`img_${resource.uuid}`} src={resource.uri} className="w-full h-full object-cover object-center"/>);
            }
        }
    }

    return (
        <>
            <div className="mb-5 w-full h-[350px]">
                { headers.map(h => previewHeader(h)) }
            </div>
            <form id="story-form" className="w-full grid grid-flow-row gap-3" onSubmit={createOrEdit}>
                <input type="text" name="headers" value={storyForm.headers} onChange={handleHeadersChange} className="block w-full border rounded-sm p-3" placeholder="* Headers"/>
                <input type="text" name="slug" value={storyForm.slug} onChange={handleSlugChange} className="block w-full border rounded-sm p-3" placeholder="* Slug"/>
                <input type="text" name="title" value={storyForm.title} onChange={handleTitleChange} className="block w-full border rounded-sm p-3" placeholder="* Title"/>
                <input type="text" name="subtitle" value={storyForm.subtitle || ''} onChange={handleSubtitleChange} className="block w-full border rounded-sm p-3" placeholder="Subtitle"/>
                <StoryEditor defaultContent={storyForm.body} onContentChangeCB={handleBodyChange}/>
                <input type="text" name="topics" value={storyForm.topics} onChange={handleTopicsChange} className="block w-full border rounded-sm p-3" placeholder="* Topics"/>
                <input type="text" name="tags" value={storyForm.tags|| []} onChange={handleTagsChange} className="block w-full border rounded-sm p-3" placeholder="* Tags"/>
                <select value={storyForm.status} onChange={handleStatusChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select staus --</option>
                    <option value="DRAFT">Draft</option>
                    <option value="READY_TO_PUBLISH">Ready to publish</option>
                    <option value="PUBLISHED">Published</option>
                    <option value="UNPUBLISHED">Unpublished</option>
                </select>
                <input value={storyForm.metadata.readTime} type="number" name="readTime" onChange={handleMetadataReadTimeChange} className="block w-full border rounded-sm p-3" placeholder="* Read time"/>
                <select value={storyForm.metadata.category} onChange={handleMetadataCategoryChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select category --</option>
                    <option value="ARTICLE">Article</option>
                    <option value="REVIEW">Review</option>
                </select>
                <select value={storyForm.metadata.locale} onChange={handleMetadataLocaleChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select locale --</option>
                    <option value="el_GR">Greek</option>
                    <option value="en_EN">English</option>
                </select>
                <button type="submit" className="block bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3">Save</button>
            </form>
            <p>{message}</p>
        </>
    );
}

export default StoryForm;
