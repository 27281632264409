import { useNavigate } from 'react-router-dom';

/*
 *
 * View
**/
const Error404View = () => {
  const navigate = useNavigate();

	return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-3">Oups! The page was not found!</h2>
      <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Go back
      </button>
    </div>
  );
}

export default Error404View;
