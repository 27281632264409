import { useState, useEffect, useRef } from 'react';

// Lib
import { IGroup } from 'lib/Interfaces';

// Services
import { GroupPlacementService } from 'services/GroupPlacementService';
import { GroupService } from 'services/GroupService';

type TPlacement = {
    type: string;
    associatedUuid: string;
}

type TGroupPlacement = {
    group: string;
    order: number;
    placement: TPlacement;
}

/*
 *
 * Component
**/
const GroupPlacementForm = ({ onCreateCB = () => {} }: { onCreateCB?: (name: string) => void; }) => {
    const _initialized_ = useRef<boolean>(false);
    const [groups, setGroups] = useState<IGroup[]>();
    const [groupPlacementForm, setGroupPlacementForm] = useState<TGroupPlacement>({ group: '', order: 100, placement: { type: '', associatedUuid: '' } });
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        if (_initialized_.current) return;
        // -> Start: execute once
        fetchGroups();
        // End: execute once <-
        _initialized_.current = true;
    }, []);

    const fetchGroups = () => {
        GroupService.getAll((data) => {
            setGroups(data);
        });
    }

    const create = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const response = await GroupPlacementService.create(groupPlacementForm);
        setMessage(response.data?.placement.uuid as string);
        onCreateCB(response.data?.placement.type as string);
    }

    const handleGroupChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setGroupPlacementForm({
            ...groupPlacementForm,
            group: event.currentTarget.value
        });
    }

    const handleTypeChange = (event: React.FormEvent<HTMLSelectElement>): void => {
        setGroupPlacementForm({
            ...groupPlacementForm,
            placement: {
                ...groupPlacementForm.placement,
                type: event.currentTarget.value
            }
        });
    }

    const handleAssocUuidChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setGroupPlacementForm({
            ...groupPlacementForm,
            placement: {
                ...groupPlacementForm.placement,
                associatedUuid: event.currentTarget.value
            }
        });
    }

    const handleOrderChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setGroupPlacementForm({
            ...groupPlacementForm,
            order: parseInt(event.currentTarget.value)
        });
    }

    return (
        <>
            <form id="mediar-directory" className="w-full grid grid-flow-row gap-3" onSubmit={create}>
                <select onChange={handleGroupChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select group --</option>
                    {
                        groups?.map((group, $key) =>
                            <option key={`group_${$key}`} value={group.uuid}>{group.name}</option>
                        )
                    }
                </select>
                <select onChange={handleTypeChange} className="block w-full border rounded-sm p-3">
                    <option>-- Select type --</option>
                    <option value="STORY">Story</option>
                    <option value="RESOURCE">Resource</option>
                </select>
                <input type="text" name="name" onChange={handleAssocUuidChange} className="block w-full border rounded-sm p-3" placeholder="* Assoc UUID"/>
                <input type="number" name="order" onChange={handleOrderChange} className="block w-full border rounded-sm p-3" placeholder="* Order"/>
                <button type="submit" className="block bg-primary hover:bg-secondary text-white text-base p-2 rounded mt-3">Create</button>
            </form>
            <p>{message}</p>
        </>
    );
}

export default GroupPlacementForm;