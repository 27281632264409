import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Lib
import { IPageResponse, ITag } from 'lib/Interfaces';

// Services
import { TagService } from 'services/TagService';

/*
 *
 * Component
**/
const TagCollection = ({ query = null }: { query?: string | null; }) => {
    const [page, setPage] = useState<number>(0);
    const [tags, setTags] = useState<IPageResponse<ITag>>();

    useEffect(() => {
        fetchTags();
    }, [query, page]);

    const fetchTags = () => {
        TagService.getAll({ query, page, size: 20 }, (data) => {
            setTags(data);
        });
    }

    const loadMore = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="flex flex-col">
                {
                    tags?.elements?.map((tag, $key) =>
                        <div key={`tag_${$key}`} className="p-5 bg-slate-100 mb-5 last:mb-0">
                            <h3 className="text-lg font-bold text-slate-700">{tag.name} ({tag.slug})</h3>
                            <h4 className="text-sm text-slate-700">{tag.uuid}</h4>
                            <div>
                                <Link to={`/nav/tags/form?tag=${tag.uuid}`} className="hover:underline">&#9998; Edit</Link>
                            </div>
                        </div>
                    )
                }
                {
                    (tags?.totalPages && (page + 1) < tags?.totalPages) &&
                    <button onClick={loadMore}>Load more</button>
                }
            </div>
        </>
    );
}

export default TagCollection;