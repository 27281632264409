// Lib
import { LogError } from 'lib/ErrorLogger';
import { rest, getSecurityToken } from 'lib/http';
import { IApiResponse, IFileUpload, IGenericError } from 'lib/Interfaces';

type TFileUploadRequest = {
    files: TFileRequest[];
};

type TFileRequest = {
    source: string;
};

/*
 *
 * Service
**/
export const FileUploadService = {
    _name_: 'FileUploadService',
    upload: async function(payload: TFileUploadRequest, onSuccessCB: (response: IFileUpload) => void = () => {}, onErrorCB: (err: IGenericError) => void = () => {}): Promise<IApiResponse<IFileUpload>> {
        const apiKey = getSecurityToken();
        const config = {
            headers: {
              "x-api-key": `${apiKey}`,
            },
            params: {}
        };
        
        return rest.post(`/files/upload`, payload, config).then(response => {
            onSuccessCB(response.data);
            return { httpStatus: response.status, status: 0, message: 'ok', data: response.data };
        }).catch(err => {
            LogError(this._name_, 'upload', err);
            onErrorCB(err);
            return { httpStatus: err.status, status: -1, message: err.message, data: null };
        });
    }
}
