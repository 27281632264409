import { useEffect, useState } from "react";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Essentials, Paragraph, Bold, Italic, BlockQuote, Font, Heading, Underline, Strikethrough, Code, RemoveFormat, Subscript, Superscript, Link, CodeBlock, Highlight, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageInsert, HorizontalLine, Base64UploadAdapter, Indent, IndentBlock, Alignment, List, TodoList, } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

/*
 *
 * Component
**/
const StoryEditor = ({ defaultContent, onContentChangeCB = () => {} }: { defaultContent?: string | null; onContentChangeCB?: (content: string) => void; }) => {
    const [hasInitialContent, setHasInitialContent] = useState<boolean>(false);
    const [content, setContent] = useState<string>('');
    const [editor, setEditor] = useState<ClassicEditor>();

    useEffect(() => {
        if (!hasInitialContent && defaultContent) {
            setContent(defaultContent);
            setHasInitialContent(true);
            editor?.setData(defaultContent);
        }
    }, [defaultContent]);

    return (
        <div id="storyEditor">
            <CKEditor
                editor={ ClassicEditor }
                onReady={(elem) => {
                    setEditor(elem);
                }}
                onChange={(e, d) => {
                    onContentChangeCB(d.data.get())
                }}
                config={ 
                    {
                        licenseKey: 'GPL',
                        plugins: [ Essentials, Paragraph, RemoveFormat, Bold, Italic, Underline, Strikethrough, Subscript, Superscript, Code, CodeBlock, BlockQuote, 
                            Heading, Font, Link, Highlight, HorizontalLine, Indent, IndentBlock, Alignment, List, TodoList,
                            Image, ImageInsert, ImageCaption, ImageResize, ImageStyle, ImageToolbar, Base64UploadAdapter, ],
                        toolbar: [ 
                            'undo', 'redo', 
                            '|', 'heading', 
                            '|', 'fontSize', 'fontColor', 'fontBackgroundColor',
                            '|', 'removeFormat', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', 
                            '|', 'alignment', 'outdent', 'indent',
                            '|', 'bulletedList', 'numberedList', 'todoList',
                            '|', 'blockQuote', 'highlight', 'horizontalLine',
                            '|', 'code', 'codeBlock', 
                            '|', 'link', 'insertImage', 
                        ],
                        initialData: content,
                        placeholder: 'Type your content here',
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                            ]
                        },
                        image: {
                            resizeOptions: [
                                {
                                    name: 'resizeImage:original',
                                    label: 'Default image width',
                                    value: null
                                },
                                {
                                    name: 'resizeImage:25',
                                    label: '25% page width',
                                    value: '25'
                                },
                                {
                                    name: 'resizeImage:50',
                                    label: '50% page width',
                                    value: '50'
                                },
                                {
                                    name: 'resizeImage:75',
                                    label: '75% page width',
                                    value: '75'
                                },
                                {
                                    name: 'resizeImage:100',
                                    label: '100% page width',
                                    value: '100'
                                }
                            ],
                            toolbar: [
                                'imageTextAlternative', 'toggleImageCaption',
                                '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText',
                                '|', 'resizeImage'
                            ]
                        },
                        list: {
                            properties: {
                                styles: true,
                                startIndex: true,
                                reversed: true
                            }
                        },
                        link: {
                            addTargetToExternalLinks: true,
                            defaultProtocol: 'https://'
                        },
                    }
                }
            />
        </div>
    );
}

export default StoryEditor;
