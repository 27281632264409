import React from 'react';
import { Outlet } from "react-router-dom";

// Components
import Navigation from 'components/nav/Navigation';
import Footer from 'components/foot/Footer';

/*
 *
 * Layout
**/
const AppLayout = () => {
  return (
    <React.Fragment>
      <nav>
        <div className="container">
          <Navigation/>
        </div>
      </nav>
      <main>
        <div className="container">
          <Outlet/>
        </div>
      </main>
      <footer>
        <div className="container">
          <Footer/>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default AppLayout;
